import React from "react";
import { Box, Layout } from "../components";

const NotFoundPage = () => (
  <Layout title="404: Not found">
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight="40vh"
      flexDirection="column"
    >
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Box>
  </Layout>
);

export default NotFoundPage;
